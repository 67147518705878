import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as style from "../../styles/thanks.module.scss"

const Thanks = () => {
  if (typeof document !== "undefined") {
    const ref = document.referrer
    const pattern = "/contact"
    if (ref.indexOf(pattern) === -1) {
      window.location.href = "/"
      console.log("ng")
    }
  }

  return (
    <Layout>
      <Seo
        title="Thanks | Contact | 株式会社アローズ"
        description="私たちができること向かっていく未来。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
      />
      <div id="contact" className={style.contactWrap}>
        <div className={style.head}>
          <div className={style.inner}>
            <h1>CONTACT</h1>
            <h2 className={style.catch}>お問い合せ</h2>
          </div>
        </div>
        <div className={style.thankWrap}>
          <p>送信しました</p>
          <p>お問い合せ頂きまして誠にありがとうございます。</p>
          <p>
            担当者から3営業日以内に回答させて頂きますので、しばらくお待ちください。
            <br />
            ゴールデンウィーク、お盆、年末年始については、
            <br />
            通常よりお時間がかかる場合がございます。あらかじめご了承ください。
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Thanks
